import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import PageScrollTop from './pageToTop/PageScrollTop'
import BusinessConsulting2 from "./pages/BusinessConsulting2";
import './assets/scss/style.scss';
import Contact from './contact/Contact'

const App = () => {
  return (
    <Router>
      <Routes>

       {/* <PageScrollTop> */}
       <Route path='/' element={<BusinessConsulting2/>}/>
       <Route path='/contact' element={<Contact/>} />
      </Routes>
       {/* </PageScrollTop> */}
    </Router>
  )
}

export default App